
import './style.css';
import Logo from './images/logo-no-background.png';

function Menu({logOut, login, profile}) {
    return (
        <div className="container">
            <nav id="menu" className="navbar navbar-expand-lg bg-light fixed-top" role="navigation">
                <a href="#" className="navbar-brand">
                    <img src={Logo} className="logo" alt='' />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
               <ul className="navbar-nav mr-auto">
               </ul>
               <ul className="navbar-nav">
                    <li className="nav-item active">
                         <a className="nav-link smoothScroll" href="#home">Home</a></li>
                    <li className="nav-item">
                         <a className="nav-link smoothScroll" href="#products">Products</a></li>
                    <li className="nav-item">
                         <a className="nav-link smoothScroll" href="#services">Services</a></li>
                    <li className="nav-item">
                         <a className="nav-link smoothScroll" href="#company">Company</a></li>
                    <li className="nav-item">
                         <a className="nav-link smoothScroll" href="#contact">Contact</a></li>
               </ul>
                </div>
          {profile ? (
        <div className="navbar">
            <ul className="navbar-nav">
            <li className="nav-item">
                <img className="profile" src={profile.picture} alt="" />
            </li>
            <li className="nav-item">
                <p className="nav-link">{profile.name}</p>
            </li>
            <li className="nav-item">
                <input type="button" onClick={logOut} className="form-control" id="logout" value="Log Out" />
            </li>
            </ul>
        </div>
    ) : (
        <div className="navbar">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <input type="button" onClick={login} className="form-control" id="login" value="Sign in" />
                </li>
            </ul>
        </div>
    )}
     </nav>
          
</div>
    );
}
export default Menu;
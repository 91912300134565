import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Menu from './Menu';
import './App.css';
import Features from './Features';
import Products from './Products';
import Services from './Services';
import Company from './Company';
import Contact from './Contact';

function App() {
  const [ user, setUser ] = useState([]);
  const [ profile, setProfile ] = useState(null);

  const login = useGoogleLogin({
      onSuccess: (codeResponse) => setUser(codeResponse),
      onError: (error) => console.log('Login Failed:', error)
  });
  useEffect(
    () => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    setProfile(res.data);
                })
                .catch((err) => console.log(err));
        }
    },
    [ user ]
  );
    // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
      googleLogout();
      setProfile(null);
  };
  return (
    <div>
      <Menu logOut={logOut} login={login} profile={profile} />
      <Features />
      <Products />
      <Services />
      <Company />
      <Contact />
</div>
);
}

export default App;

import SoftwareDevelopment from './images/software-development.png'
import CloudComputing from './images/cloud-computing.png'
import Consultancy from './images/consultancy.png'
import Support from './images/support.png'

function Services() {
    return (
        <div>
            <section id="services" className="feature" data-stellar-background-ratio="0.5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-title">
                        <h1>Services</h1>
                         </div>
                         <div className="row service">
                            <div className="col-md-6 col-sm-12">
                                <h2>Software Development</h2>
                                <p>We provide market-leading solutions. Our experience specialists build perfectly optimized tools powered 
                                    by internal processes that promove colaboration, creativity and excellence</p>
                                <h3>MVP</h3>
                                <ul>
                                        <li>Low-fidelity and high-fidelity MVP development</li>
                                        <li>Mockups and prototyping</li>
                                        <li>MVP design</li>
                                </ul>
                                <h3>Web applications</h3>
                                <p>We guarantee products with reausable components, state-of-art designs following UI/UX guides and supported by secure and high-performance architectures</p>
                                <h3>Custom development</h3>
                                <p>We are your partner in digital transformation, leading each project with purpose and precision</p>
                            </div>
                            <div className="col-md-6 hide-img">
                                <img className="software-development" src={SoftwareDevelopment} alt="" />
                            </div>
                         </div>
                         <div className="row service">
                              <div className="col-md-6 hide-img">
                                   <img className="cloud-computing" src={CloudComputing} alt="" />
                              </div>
                              <div className="col-md-6 col-sm-12">
                                   <h2>Cloud Computing</h2>
                                   <p>We transform the way the enterprise use their resources incorporating cloud based tools changing business models therefore accelerating transformation.</p>
                                        <h3>Cloud application development</h3>
                                        <p>We create, migrate and transform solutions into public, private or hybrid environments</p>
                                        <h3>Cloud-related services</h3>
                                        <p>Using cloud technologies and up-to-date approaches, we provide great solutions for your requests</p>
                                        <h3>Integrations</h3>
                                        <p>We speed up your business with cloud solutions increacing resiliency, reliability and cost-efficiency</p>
                              </div>
                         </div>
                         <div className="row service">
                              <div className="col-md-6 col-sm-12">
                                   <h2>Expert Consultancy</h2>
                                   <p>We incorporate technology into your business strategy promoving growth to obtain greater revenues.</p>
                                        <h3>Enterprise architecture</h3>
                                        <p>We Guide reorganization efforts with an evolving enterprise architecture.</p>
                                        <h3>Digital transformation</h3>
                                        <p>Exploring business needs and goals, we propose adecuate solutions ensuring also a fluent implementation.</p>
                                        <h3>IT service management guidance</h3>
                                        <p>Evaluating current status we help the enterprise to modify processes that support current and future needs</p>
                              </div>
                              <div className="col-md-6 hide-img">
                                   <img className="consultancy" src={Consultancy} alt="" />
                              </div>
                         </div>
                         <div className="row service">
                              <div className="col-md-6 hide-img">
                                   <img className="support" src={Support} alt="" />
                              </div>
                              <div className="col-md-6 col-sm-12">
                                   <h2>Maintenance & Support</h2>
                                   <p>We ensure longevity for your systems, integrating modern technologies into legacy systems. Added value is for
                                         everyday work analyzing new objectives with actual solution</p>
                                   <p>Following industry best practices we offer monitoring of the systems analyzing results and providing solutions. 
                                        We promote Site Reliability Engineering best practices and OWASP community guidance.</p>
                              </div>
                         </div>
                    </div>
                </div>
            </div>
     </section>
        </div>
    );
}
export default Services;
import Cooking from './images/cooking-logo-color.png';
import RealEstate from './images/real-estate-logo-color.png';
import BookWorms from './images/book-worms-logo-color.png';

function Products() {
    return (
        <div>
            <section id="products" className="feature">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="section-title">
                                <h1>Products</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row product-row align-items-center">
                        <div className="col-md-3 col-sm-6">
                            <img src={Cooking} className="product" alt="Cooking" />     
                        </div>
                        <div className="col-md-9 col-sm-6">
                            <p>Modern web and mobile application set with a receipts database and e-commerce site offering chef services</p>
                            <p>The receipts database acts as a wrapper for different receipts providers, and also is a community 
                              for anyone that want to create and manage their own receipts.</p>
                            <p> Also de e-commerce site is a community where each entrepreneur offering gastronomy services can 
                              publish any product wanted.</p>
                        </div>
                    </div>
                    <div className="row product-row align-items-center">
                        <div className="col-md-9 col-sm-6">
                            <p>E commerce application for real state agencies and the community with rich interface for buyers 
                              and intuitive backoffice for sellers.</p>
                            <p>It is also a real estates database with publications from different providers improving user experience</p>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <img src={RealEstate} className="product" alt="Real Estate" /> 
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-3 col-sm-6">
                            <img src={BookWorms} className="product" alt="Book Worms" />     
                        </div>
                        <div className="col-md-9 col-sm-6">
                            <p>E-commerce application with rich interface offering books from different providers. This is the 
                              perfect site for bookworms where they can find all the books they love. Also has an integration 
                              API so the providers can integrate with the site providing a fast way for knowing new books and
                               the complete list of available books</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Products;
function Company() {
    return (
        <>
    
     <section id="company" className="feature" data-stellar-background-ratio="0.5">
          <div className="container">
               <div className="row">

                    <div className="col-md-12 col-sm-12">
                         <div className="section-title">
                              <h1>Company</h1>
                              <p>SwarmyTech specializes in Cloud Computing, creating and evolving solutions and products with focus in the community.
                                   Solving problems by designing and building in an agile and scalable manner</p>
                         </div>
                    </div>

                    <div className="col-md-12 col-sm-12">
                         <div className="tab-pane-item">
                              <h2>Mision</h2>
                              <p>We work for the global community delivering tools to improve life quality to people.</p>
                         </div>
                         <div className="tab-pane-item">
                              <h2>Vision</h2>
                              <p>Be a company in which everyone thinks due to the support to the community.</p>
                         </div>
                    
                         <div className="tab-pane-item">
                              <h2>Values</h2>
                              <div className="row">
                                   <div className="col-md-6">
                                        <div className="row">
                                             <div className="col-md-4 value">Creativity</div>
                                             <div className="col-md-4 value">Diversity</div>
                                             <div className="col-md-4 value">Inclusion</div>
                                             <div className="col-md-4 value">Colaboration</div>
                                             <div className="col-md-4 value">Sustainability</div>
                                             <div className="col-md-4 value">Global citizenship</div>
                                             <div className="col-md-4 value">Adaptability</div>
                                             <div className="col-md-4 value">Continuous learning</div>
                                             <div className="col-md-4 value">Integrity</div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </section>
        </>
    );
}
export default Company;
function Contact() {
    return (
        <>
        <section id="contact" className="feature" data-stellar-background-ratio="0.5">
             <div className="container">
                  <div className="row">
                       <div className="col-md-12 col-sm-12">
                            <div className="section-title">
                                 <h1>Say hello to us</h1>
                            </div>
                       </div>
   
                       <div className="col-md-offset-1 col-md-10 col-sm-12">
                            <form id="contact-form">
                                 <div className="col-md-4 col-sm-4">
                                      <input type="text" className="form-control" placeholder="Full name" name="name" required="" />
                                 </div>
                                 <div className="col-md-4 col-sm-4">
                                      <input type="email" className="form-control" placeholder="Email address" name="email" required="" />
                                 </div>
                                 <div className="col-md-4 col-sm-4">
                                      <input type="button" onclick="sendMail()" className="form-control" name="sendMessage" value="Send Message" />
                                 </div>
                                 <div className="col-md-12 col-sm-12">
                                      <textarea className="form-control" rows="8" placeholder="Your message" name="message" required=""></textarea>
                                 </div>
                            </form>
                       </div>
   
                  </div>
             </div>
        </section>       
        </>
    );
}
export default Contact;